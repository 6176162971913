
.bubble {
    background-color: whitesmoke;
    color: black;
    font-weight: bold;
    box-shadow: -8px -8px 20px 0px #fff9, -6px -6px 6px 0px #fff9,
      8px 8px 20px 0px #0001, 5px 5px 6px 0px #0001, inset 0px 0px 0px 0px #fff9,
      inset 0px 0px 0px 0px #0001, inset 0px 0px 0px 0px #fff9,
      inset 0px 0px 0px 0px #0001;
    font-size: 16px;
    line-height: 2;
    border-radius: 10px;
    padding: 2px 20px;
    margin-top: 60px;
  
    &:nth-child(1) {
      
      border: 2px solid #c7cace;
      border-left: 10px solid #695096;
    }
    &:nth-child(1):after {
      content: '';
      display: block;
      position: relative;
      margin-top: -40px;
      padding-top: 0px;
      bottom: -30px;
      left: 20px;
      border-width: 30px 0 0 30px;
      border-style: solid;
      border-color: #c7cace transparent;	
      width: 2px;
      border-radius: 0 0 40px 0;
    }
  
    &:nth-child(2) {
      border-right: 10px solid blue;
    }
    &:nth-child(2):after {
      content: '';
      display: block;
      position: relative;
      margin-top: -40px;
      padding-top: 0px;
      bottom: -30px;
      left: 400px;
      border-width: 30px 30px 0 0;
      border-style: solid;
      border-color: #e0e5ec transparent;
      width: 2px;
      border-radius: 0 0 0 40px;
    }
  
    &:nth-child(3) {
      border-left: 10px solid limegreen;
    }
    &:nth-child(3):after {
      content: '';
      display: block;
      position: relative;
      margin-top: -40px;
      padding-top: 0px;
      bottom: -30px;
      left: 20px;
      border-width: 30px 0 0 30px;
      border-style: solid;
      border-color: #e0e5ec transparent;	
      width: 2px;
      border-radius: 0 0 40px 0;
    }
  
    .detail {
      font-style: italic;
      display: inline;
      float: right;
    }
  }
  